import React, { memo, useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import createDOMPurify from "dompurify";
import { parseHTML } from "linkedom";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";

const { window } = parseHTML("");
const DOMPurify = createDOMPurify(window);

const TemplateParser = (props) => {
  const history = useHistory();
  let location = history.location.pathname?.includes("company");
  const containerWidthRef = useRef(null);
  const [widthOfContainer, setWidthOfContainer] = useState(null);

  const emptyRemainingSlugs = (replacedTemplate) => {
    const paramsPattern = /[^{}]+(?=})/g;
    let extractParams = replacedTemplate.match(paramsPattern);
    if (extractParams && extractParams.length > 0) {
      extractParams.forEach((param) => {
        replacedTemplate = replacedTemplate.replace(
          "{" + param + "}",
          "&nbsp;"
        );
      });
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(replacedTemplate, "text/html");
    doc.querySelectorAll(".row").forEach(function (row) {
      if (!row.querySelector("custom")) {
        // Checks if a 'custom' tag is not present in the child
        row.classList.add("d-none"); // Adds 'd-none' class if no 'custom' tag is found
      }
    });
    replacedTemplate = doc.body.innerHTML;
    return replacedTemplate;
  };

  const styleConverter = (s) => {
    let style = {};
    let properties = s.split(";");
    properties.forEach((p) => {
      let propertyNames = p.split(":");
      if (propertyNames && propertyNames.length > 0) {
        style[
          propertyNames[0].replace(/-([a-z])/g, function (g) {
            return g[1].toUpperCase();
          })
        ] = propertyNames[1];
      }
    });
    return style;
  };

  const parseHtml = (ele) => {
    let content = {};
    let template = props.template;

    try {
      content = JSON.parse(props.content);
      Object.keys(content).forEach((ind) => {
        if (content[ind].type) {
          const checkingTemplate = template.indexOf(ind);
          if (ind === "left_over_data" && checkingTemplate === -1) {
            template +=
              '<div class="row"><div class="col-lg-12">{left_over_data}</div><div class="col-lg-12">{left_over_images}</div></div>';
          }

          let replacer = "";
          replacer = `<div class='${
            props.isDeleted ? "content_deleted" : ""
          }'><custom class='${ind}' /></div>`;

          template = template.replace("{" + ind + "}", replacer);
        }
      });
      return emptyRemainingSlugs(template);
    } catch (e) {
      console.log("parse html error: ", e);
    }
  };

  const transform = (node) => {
    let content = JSON.parse(props.content);

    if (node.type === "tag" && node.name === "custom") {
      if (
        content[node.attribs.class] &&
        content[node.attribs.class].type === "text"
      ) {
        return (
          <>
            {content[node.attribs.class].value.trim().length > 0 &&
              widthOfContainer &&
              renderPublishSvgContent(content[node.attribs.class].value)}
          </>
        );
      } else if (
        content[node.attribs.class] &&
        content[node.attribs.class].type === "image" &&
        !Array.isArray(content[node.attribs.class].value)
      ) {
        let prefix = "";
        let temp = content[node.attribs.class].value
          ? content[node.attribs.class].value
          : "";
        content[node.attribs.class].value = temp;
        if (temp.startsWith("http")) {
          prefix = "";
        } else if (temp.startsWith("data:")) {
        } else {
          prefix = process.env.REACT_APP_TBRI_IMAGE_URL;
        }
        return (
          <>
            <div className="d-flex">
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.class].value.trim().length > 0 && (
                  <img
                    style={styleConverter(
                      content[node.attribs.class].style
                        ? content[node.attribs.class].style
                        : ""
                    )}
                    src={`${prefix + content[node.attribs.class].value}`}
                    alt={"content pic"}
                    className={"content_image"}
                  />
                )}
              </div>
              <div className="mt-11px overflow-auto font-Calibri">
                {content[node.attribs.class].value.trim().length === 0 &&
                  props.isEditable && <p>{props.emptyContentMessage}</p>}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="d-flex align-items-start my-3"></div>
          </>
        );
      }
    }
  };

  const renderPublishSvgContent = (svg_string) => {
    let new_width = location
      ? `${widthOfContainer}`
      : `${widthOfContainer - 200}`;
    let new_height = `${(new_width * 7.5) / 10}`;
    let new_svg_string = svg_string.replace(/<\/?p>/g, "");
    const bodyMatch = new_svg_string.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
    let extractedBody = bodyMatch ? bodyMatch[1] : new_svg_string;

    if (extractedBody?.search("<svg") !== -1) {
      extractedBody = extractedBody
        .replace(/aria-hidden="[^"]*"/, `aria-hidden="true"`)
        .replace(/width="[^"]*"/, `width="${new_width}px"`)
        .replace(/height="[^"]*"/, `height="${new_height}px"`);

      return (
        <div className="font-Calibri published">
          <SVG
            src={extractedBody}
            width={location ? new_width - 50 : new_width - 100 + "px"}
            height={new_height - 10 + "px"}
            title="Content"
          />
        </div>
      );
    } else {
      let content = DOMPurify.sanitize(extractedBody, {
        FORBID_TAGS: ["style"],
      });

      return (
        <div className="font-Calibri published">
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      );
    }
  };

  useEffect(() => {
    let containerWidth = containerWidthRef.current?.clientWidth;
    setWidthOfContainer(Number(containerWidth) || 1000);
  }, []);

  return (
    <div
      ref={containerWidthRef}
      style={{
        position: "relative",
        display: "block",
        textAlign: "justify",
        overflow: "hidden",
      }}
    >
      {parse(parseHtml(), { replace: transform })}
    </div>
  );
};

export default memo(TemplateParser);
