import { Component, default as React } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import Notification from "../Components/Notification";
import profile_image from "../Images/profile-image.png";
import decodeJWT from "../lib/decodeJWT";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openSearchBar,
  displayName,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DateTimeFormatter from "../Utils/DateTimeFormatter";
import Toast from "../Utils/Toast";
import ToggleSwitch from "../Components/ToggleSwitch";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import ellipsisName from "../Utils/AddEllipsisToName";
//import { setLoggerUserDetailsFromToken, clearLoggerUserDetails } from '../Utils/SentryInitialization';
import { ApiCredentialServices } from "../Services/ApiCredentialServices";
import Copy from "../Utils/Copy";
class Header extends Component {
  constructor(props) {
    super(props);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  state = {
    loginSuccesData: null,
    isProfileMenu: false,
    popUpModel: false,
    popUpNameModel: false,
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    apiErrors: "",
    formErrors: {},
    passwordError: false,
    newPasswordError: false,
    confirmPasswordError: false,
    isSubmitted: false,
    successMessage: "",
    isPasswordMatchError: false,
    newDisplayName: "",
    displayName_id: null,
    isTrueVal: false,
    display_name: localStorage.getItem("display_name"),
    popUpApiCredentials: false,
    clientId: null,
    userId: null,
    APIKey: null,
    secretKey: null,
    showNotification: true,
    is_user_active: false,
  };

  componentDidMount() {
    const loginSuccesData = this.props.home.loginUserSuccess;
    let displayName =
      this.state.display_name === "null"
        ? loginSuccesData.first_name
        : this.state.display_name;
    this.props.setDisplayName(displayName);
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    this.setState({
      loginSuccesData,
      clientId: tokenDetails?.user?.client_id || "",
      userId: tokenDetails?.user?.id || "",
    });
    setTimeout(() => {
      this.setState({ showNotification: false });
    }, 15000);
  }

  handleOutsideClick(e) {
    if (e && e.target && this.node) {
      if (this.node.contains(e.target)) {
        return;
      }
      this.toggleProfileMenu();
    }
  }

  toggleProfileMenu = () => {
    if (!this.state.isProfileMenu) {
      document.addEventListener("click", this.handleOutsideClick, false);
    } else {
      document.removeEventListener("click", this.handleOutsideClick, false);
    }
    this.setState({
      isProfileMenu: !this.state.isProfileMenu,
    });
  };

  userLogout = () => {
    UserServices.getUserLogout()
      .then((res) => {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("display_name");
        localStorage.removeItem("designation");
        this.props.loginSuccess({});
        //clearLoggerUserDetails();
        this.props.history.push(
          formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  handlePopUp = () => {
    this.setState({
      successMessage: "",
      apiErrors: "",
      popUpModel: !this.state.popUpModel,
      currentPasswordError: false,
      newPasswordError: false,
      confirmPasswordError: false,
      isPasswordMatchError: false,
      isSubmitted: false,
    });
  };

  // pop up for change display name
  handleNamePopUp = () => {
    this.setState({
      popUpNameModel: !this.state.popUpNameModel,
      isTrueVal: true,
      displayName_id: this.props.home.loginUserSuccess.id,
    });
  };

  // pop up for Api Credentials
  handleApiCredPopUp = () => {
    this.getApiCredential();
    this.setState({
      popUpApiCredentials: !this.state.popUpApiCredentials,
      isTrueVal: true,
    });
  };

  isNameValid = (name) => {
    const regex = new RegExp(constants.REG_EX.NAME_REG_EX);
    return regex.test(name);
  };

  handleNameInputChange = (e) => {
    const { value } = e.target;
    const isTrueVal = !value || this.isNameValid(value);
    this.setState({
      newDisplayName: value,
      isTrueVal,
    });
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  isFormValid = () => {
    var re = new RegExp(constants.REG_EX.PASSWORD_REG_EX);
    let currentPasswordError = this.state.currentPassword === "";
    let newPasswordError = !re.test(this.state.newPassword);
    let isPasswordMatchError = !(
      this.state.currentPassword !== this.state.newPassword
    );
    let confirmPasswordError =
      !re.test(this.state.confirmPassword) ||
      this.state.newPassword !== this.state.confirmPassword;

    this.setState({
      currentPasswordError,
      newPasswordError,
      confirmPasswordError,
      isPasswordMatchError,
      isSubmitted: true,
    });
    if (
      currentPasswordError === false &&
      newPasswordError === false &&
      confirmPasswordError === false &&
      isPasswordMatchError === false
    ) {
      return true;
    }
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      let body = {
        password: this.state.currentPassword,
        new_password: this.state.newPassword,
        confirm_password: this.state.confirmPassword,
      };
      UserServices.changePassword(body)
        .then((data) => {
          this.setState({
            apiErrors: "",
            successMessage: data.data.message,
          });
          setTimeout(() => {
            this.userLogout();
          }, 1500);
        })
        .catch((error) => {
          this.setState({
            apiErrors:
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        });
    }
  };

  // function change display name
  handleNameSubmit = (e) => {
    e.preventDefault();
    let body = {
      display_name: this.state.newDisplayName,
      id: this.state.displayName_id,
    };
    UserServices.updateDisplayName(body)
      .then((data) => {
        this.setState({
          apiErrors: "",
          successMessage: data.data.message,
          display_name: this.state.newDisplayName,
        });
        localStorage.setItem("display_name", this.state.newDisplayName);
        this.props.setDisplayName(this.state.newDisplayName);
        setTimeout(() => {
          this.handleNamePopUp();
          this.setState({ newDisplayName: "", successMessage: "" });
        }, 1500);
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  // function to generate API Credentials
  handleApiCredGeneration = (e) => {
    e.preventDefault();
  };
  // Calling an api for generating api credentials
  generateAPIKeyCred = () => {
    let body = {
      client_id: this.state.clientId,
      user_id: this.state.userId,
    };
    ApiCredentialServices.generateApiCredentials(body)
      .then((data) => {
        this.setState({
          apiErrors: "",
          secretKey: data?.data?.data?.api_secret,
          APIKey: data?.data?.data?.api_key,
        });
        Toast("API Key and Secret Generated Successfully.", "success");
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  // Calling an api for re-generating api credentials
  regenerateApiCredentials = () => {
    let body = {
      client_id: this.state.clientId,
      user_id: this.state.userId,
    };
    ApiCredentialServices.regenerateApiCredentials(body)
      .then((data) => {
        this.setState({
          apiErrors: "",
          // successMessage: data?.data?.message,
          secretKey: data?.data?.data?.api_secret,
          APIKey: data?.data?.data?.api_key,
        });
        Toast(data?.data?.message, "success");
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  // Calling an api for getting api credentials
  getApiCredential = () => {
    let body = {
      is_active: true,
    };
    ApiCredentialServices.getApiCredential(body)
      .then((data) => {
        if (data?.data?.data) {
          this.setState({
            APIKey: data?.data?.data?.api_key,
            secretKey: data?.data?.data?.api_secret,
            is_user_active: data?.data?.data?.is_active,
          });
        } else {
          this.setState({
            APIKey: null,
            secretKey: null,
            is_user_active: true,
          });
        }
      })
      .catch((error) => {
        this.setState({
          apiErrors:
            error && error.data && error.data.error
              ? error.data.error
              : constants.ERROR.SOMETHING_WENT_WRONG,
        });
      });
  };

  // navitage to the Preference center
  navigateToPreferenceCenter = () => {
    this.props.history.push(
      formatRoute(constants.APPLICATION_ROUTE.MANAGE_PREFERENCES.ROUTE, {})
    );
    this.toggleProfileMenu();
  };

  getFirstLetters(str) {
    const firstLetters = str
      .split(" ")
      .map((word) => word[0])
      .join("");

    return firstLetters;
  }

  render() {
    let pathName = window.location.pathname;
    let tokenDetails = decodeJWT(localStorage.getItem("token"));
    let { APIKey, secretKey, is_user_active } = this.state;

    return (
      <>
        <header>
          <div className="header-content-wrapper h-100">
            <div className="header-content h-100 d-flex align-items-center">
              <nav className="navbar navbar-expand-lg navbar-light p-0">
                <div className="logo d-flex align-items-center">
                  <a
                    onClick={() => {
                      this.props.openSearchBar(false);
                    }}
                    // onClick={() => {
                    //   this.props.openPreference(false);
                    // }}
                    className="navbar-brand m-0 nav_logo"
                    href={formatRoute(
                      constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>

                  <a
                    className="navbar-brand d-md-none header-logo"
                    href={formatRoute(
                      constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE,
                      {}
                    )}
                  >
                    <img
                      style={{ width: "80px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/staging/TBR_2color_no_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo /> */}
                  </a>
                </div>
                {tokenDetails.role.short_name === "admin" && (
                  <div>
                    <ToggleSwitch data={this.state.loginSuccesData} />
                  </div>
                )}
                <div className="site_name ml-md-auto">
                  <span>INSIGHT CENTER</span>
                </div>

                <div className="ml-auto">
                  <div className="">
                    <div className="login_sec">
                      <ul className="navbar-nav justify-content-end align-items-center">
                        {pathName !== "/dashboard/list" ? (
                          <li className="mr-3">
                            <div
                              className="input_search d-flex align-items-center justify-content-center"
                              onClick={this.props.changepopover}
                            >
                              <i
                                className="fa fa-search text-grey-clr"
                                aria-hidden="true"
                              ></i>
                              <span className="search_text">
                                Search Insight Center
                              </span>
                              <span className="slash_btn">
                                <span>/</span>
                              </span>
                            </div>
                          </li>
                        ) : (
                          ""
                        )}

                        <li className="mr-3">
                          <Button
                            id="PopoverLegacy"
                            style={{
                              padding: 0,
                              border: "none",
                              background: "none",
                              boxShadow: "none",
                            }}
                            type="button"
                            className="d-inline-flex"
                            data-testid="popover"
                          >
                            <span className="material-icons text-ligntblue2">
                              mail
                            </span>
                          </Button>
                          <UncontrolledPopover
                            placement="bottom"
                            target="PopoverLegacy"
                            trigger="legacy"
                            className="custom-popover"
                          >
                            <PopoverHeader>Email Support</PopoverHeader>
                            <PopoverBody>
                              Any queries/help, please contact us <br />
                              <a
                                style={{ color: "#0645AD" }}
                                href="mailto:icsupport@tbri.com"
                                data-testid="emailanchortag"
                              >
                                icsupport@tbri.com
                              </a>
                            </PopoverBody>
                          </UncontrolledPopover>
                        </li>
                        <li
                          onClick={this.toggleProfileMenu}
                          className={`user-details mr-0 ${
                            this.state.isProfileMenu ? "active" : ""
                          }`}
                        >
                          <div className="d-flex align-items-center">
                            <span className="profile_circle">
                              {this.getFirstLetters(
                                `${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`
                              )}
                            </span>
                            <div className="user-details-block d-none d-md-flex flex-column align-items-center">
                              <span className="w-100">
                                {ellipsisName(this.props.home.displayName)}
                              </span>

                              <span className="user-role">
                                {this.props.home.loginUserSuccess
                                  .designation !== null
                                  ? this.props.home.loginUserSuccess.designation
                                  : tokenDetails.role.name}
                              </span>
                            </div>
                            <span className="material-icons arrow-drown-down">
                              arrow_drop_down
                            </span>
                          </div>
                        </li>
                        <li className="d-none">
                          <span className="search_open">
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
        <div
          className={`login_sec user-popover ${
            this.state.isProfileMenu ? "active" : ""
          }`}
          id="loginpopover"
        >
          <ul
            ref={(node) => {
              this.node = node;
            }}
          >
            <li>
              <div className="userProfile">
                <img src={profile_image} alt="Profile" />
                <h4>{`${this.props.home.loginUserSuccess.first_name} ${this.props.home.loginUserSuccess.last_name}`}</h4>
                <p
                  data-for="displayNameUser"
                  data-tip={
                    this.state.display_name !== "null"
                      ? this.state.display_name
                      : "N/A"
                  }
                >
                  <CustomReactTooltip id="displayNameUser" />
                  Display Name:{" "}
                  {this.state.display_name !== "null"
                    ? ellipsisName(this.state.display_name)
                    : "N/A"}
                </p>
                {tokenDetails && tokenDetails.role && (
                  <span className="user-profile-role">
                    {this.props.home.loginUserSuccess.designation !== null
                      ? this.props.home.loginUserSuccess.designation
                      : tokenDetails.role.name}
                    {tokenDetails.client && tokenDetails.client.name
                      ? ` - ${tokenDetails.client.name}`
                      : ""}
                  </span>
                )}
                <p>
                  Member Since{" "}
                  {DateTimeFormatter(
                    this.props.home.loginUserSuccess.created_at
                  )}
                </p>
              </div>
            </li>
            <li>
              <span
                onClick={this.handleNamePopUp}
                className="d-inline-flex align-items-center"
                data-testid="chnagenamebtn"
              >
                <i className="material-icons-outlined">account_circle</i>
                Change Display Name
              </span>
            </li>
            <Modal
              isOpen={this.state.popUpNameModel}
              toggle={this.handleNamePopUp}
              className={"modal-md modal-w-header custom-modal primary-modal"}
              data-testid="modalpopup"
            >
              <ModalHeader toggle={this.handleNamePopUp}>
                {constants.CHANGE_NAME.HEADER_TITLE}
              </ModalHeader>
              <form
                onSubmit={this.handleNameSubmit}
                className={`col-lg-12 col-sm-12 form-wrapper p-0`}
              >
                <ModalBody>
                  {this.state.apiErrors.length > 0 && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiErrors}
                    />
                  )}
                  {this.state.successMessage.length > 0 && (
                    <Notification
                      color={"success"}
                      message={this.state.successMessage}
                    />
                  )}
                  <FormGroup>
                    <Label htmlFor="currentPassword">
                      Current Display Name
                    </Label>
                    <Input
                      type="text"
                      disabled={true}
                      value={this.state.display_name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="newPassword">
                      New Display Name
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${!this.state.isTrueVal ? "is-invalid" : ""}`}
                      type="text"
                      placeholder=""
                      id="newDisplayName"
                      name="newDisplayName"
                      onChange={this.handleNameInputChange}
                      value={this.state.newDisplayName}
                    />
                    {!this.state.isTrueVal && (
                      <div className="invalid-tooltip">{`${this.state.newDisplayName} is not valid.`}</div>
                    )}
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="modal-btn btn-outline-primary"
                    onClick={this.handleNamePopUp}
                    data-testid="cancelbtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="modal-btn"
                    color="primary"
                    type="submit"
                    role="submit"
                    disabled={
                      this.state.newDisplayName.length === 0 ||
                      !this.state.isTrueVal
                    }
                  >
                    Change Name
                  </Button>
                </ModalFooter>
              </form>
            </Modal>
            {tokenDetails?.client?.is_open_api_active === 1 && (
              <>
                <li>
                  <span
                    onClick={this.handleApiCredPopUp}
                    className="d-inline-flex align-items-center"
                    data-testid="apicredbtn"
                  >
                    <i
                      className="material-icons-outlined"
                      style={{ rotate: "90deg", paddingRight: "0px" }}
                    >
                      key
                    </i>
                    API Credentials
                  </span>
                </li>

                <Modal
                  isOpen={this.state.popUpApiCredentials}
                  toggle={this.handleApiCredPopUp}
                  className="modal-md modal-w-header custom-modal primary-modal"
                  data-testid="modalpopup"
                >
                  <ModalHeader toggle={this.handleApiCredPopUp}>
                    {constants.API_CREDENTIAL_TITLE.HEADER_TITLE}
                  </ModalHeader>
                  {is_user_active ? (
                    <form
                      onSubmit={this.handleApiCredGeneration}
                      className="col-lg-12 col-sm-12 form-wrapper p-0"
                    >
                      <ModalBody className="pb-2">
                        {this.state.apiErrors.length > 0 && (
                          <Notification
                            color={"danger"}
                            message={this.state.apiErrors}
                          />
                        )}
                        {this.state.successMessage.length > 0 && (
                          <Notification
                            color={"success"}
                            message={this.state.successMessage}
                          />
                        )}
                        <FormGroup>
                          <Label htmlFor="endpoint" style={{ color: "black" }}>
                            Endpoint
                          </Label>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Input
                              type="text"
                              value={process.env.REACT_APP_TBRI_OPEN_API_URL}
                              disabled
                            />
                            <span
                              className="material-icons-outlined copy-icon icon-fs-14 p-2 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => {
                                Copy(process.env.REACT_APP_TBRI_OPEN_API_URL);
                                Toast(
                                  "Copied Endpoint Successfully",
                                  "success"
                                );
                              }}
                              data-for="tooltip-copy-endpoint"
                              data-tip="Copy<br>Endpoint"
                              data-iscapture="true"
                            >
                              content_copy
                            </span>
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="APIKey" style={{ color: "black" }}>
                            API Key
                          </Label>
                          <div
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Input
                              className={
                                !this.state.isTrueVal ? "is-invalid" : ""
                              }
                              type="text"
                              id="APIKey"
                              name="APIKey"
                              value={APIKey}
                              disabled
                            />
                            <span
                              className="material-icons-outlined copy-icon icon-fs-14 p-2 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => {
                                Copy(APIKey);
                                Toast("Copied APIKey Successfully", "success");
                              }}
                              data-for="tooltip-copy-APIKey"
                              data-tip="Copy<br>APIKey"
                              data-iscapture="true"
                            >
                              content_copy
                            </span>
                            <CustomReactTooltip
                              id="tooltip-copy-APIKey"
                              multiline
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="secretKey" style={{ color: "black" }}>
                            API Secret Key
                          </Label>
                          <div className="d-flex align-items-center">
                            <Input
                              className={
                                !this.state.isTrueVal ? "is-invalid" : ""
                              }
                              type="text"
                              id="secretKey"
                              name="secretKey"
                              value={secretKey}
                              disabled
                            />
                            <span
                              className="material-icons-outlined copy-icon icon-fs-14 p-2 cursor-pointer"
                              aria-hidden="true"
                              onClick={() => {
                                Copy(secretKey);
                                Toast("Copied API Secret Key Successfully", "success");
                              }}
                              data-for="tooltip-copy-secretKey"
                              data-tip="Copy<br>SecretKey"
                              data-iscapture="true"
                            >
                              content_copy
                            </span>
                            <CustomReactTooltip
                              id="tooltip-copy-secretKey"
                              multiline
                            />
                          </div>
                        </FormGroup>
                      </ModalBody>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="modal-btn btn-outline-primary"
                          onClick={
                            APIKey && secretKey
                              ? this.regenerateApiCredentials
                              : this.generateAPIKeyCred
                          }
                          data-testid="cancelbtn"
                        >
                          {APIKey && secretKey ? "Regenerate" : "Generate"}
                        </Button>
                      </div>
                      <span className="d-flex mt-3 justify-content-center">
                        View API documentation and examples on{" "}
                        <a
                          href={
                            process.env.REACT_APP_TBRI_OPEN_API_URL +
                            "/api-docs"
                          }
                          className="ml-2 font-weight-bold"
                          style={{ color: "#046CD9", fontSize: "14px" }}
                          target="_blank"
                        >
                          Swagger
                        </a>
                      </span>
                    </form>
                  ) : (
                    <ModalBody>
                      <div
                        className="noaccess-body mt-2"
                        style={{ alignItems: "center" }}
                      >
                        <p
                          className="font-weight-semibold"
                          style={{ fontSize: "16px", color:"#626262" }}
                        >
                          You don't have access to the API credentials page.
                          Please contact the administrator for access.
                        </p>
                      </div>
                    </ModalBody>
                  )}
                  {!is_user_active && (
                    <ModalFooter>
                      <div className="d-flex justify-content-center">
                        <Button
                          className="modal-btn btn-outline-primary"
                          onClick={this.handleApiCredPopUp}
                          data-testid="cancelbtn"
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  )}
                </Modal>
              </>
            )}

            <li>
              <span
                onClick={this.handlePopUp}
                className="d-inline-flex align-items-center"
                data-testid="chnagepwdbtn"
              >
                <i className="material-icons-outlined">lock_reset</i>
                Change Password
              </span>
            </li>
            <Modal
              isOpen={this.state.popUpModel}
              toggle={this.handlePopUp}
              className={"modal-md modal-w-header custom-modal primary-modal"}
              data-testid="modalpopup"
            >
              <ModalHeader toggle={this.handlePopUp}>
                {constants.CHANGE_PASSWORD.HEADER_TITLE}
              </ModalHeader>
              <form
                onSubmit={this.handleSubmit}
                className={`col-lg-12 col-sm-12 form-wrapper p-0`}
              >
                <ModalBody>
                  {this.state.apiErrors.length > 0 && (
                    <Notification
                      color={"danger"}
                      message={this.state.apiErrors}
                    />
                  )}
                  {this.state.successMessage.length > 0 && (
                    <Notification
                      color={"success"}
                      message={this.state.successMessage}
                    />
                  )}
                  <FormGroup>
                    <Label htmlFor="currentPassword">
                      Current Password
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${
                        this.state.currentPasswordError ? "is-invalid" : ""
                      }`}
                      type="password"
                      placeholder=""
                      id="currentPassword"
                      name="currentPassword"
                      onChange={this.handleInputChange}
                    />
                    {this.state.currentPasswordError && (
                      <div className="invalid-tooltip">
                        Current Password is a required field
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="newPassword">
                      New Password
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${
                        this.state.newPasswordError
                          ? "is-invalid"
                          : this.state.isPasswordMatchError
                          ? "is-invalid"
                          : ""
                      }`}
                      type="password"
                      placeholder=""
                      id="newPassword"
                      name="newPassword"
                      onChange={this.handleInputChange}
                    />

                    {this.state.newPasswordError ? (
                      <div className="invalid-tooltip" data-testid="pwderror">
                        Passwords must contain at least 8 characters including
                        at least 1 of each of the following: uppercase letter,
                        lowercase letter, special character and number.
                      </div>
                    ) : this.state.isPasswordMatchError ? (
                      <div className="invalid-tooltip">
                        Current Password and New Password should be different
                      </div>
                    ) : (
                      ""
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="confirmPassword">
                      Confirm Password
                      <span className={"mandatory"}>* </span>
                    </Label>
                    <Input
                      className={`${
                        this.state.confirmPasswordError ? "is-invalid" : ""
                      }`}
                      type="password"
                      placeholder=""
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={this.handleInputChange}
                    />

                    {this.state.confirmPasswordError && (
                      <div
                        className="invalid-tooltip"
                        data-testid="pwdnotmatcherror"
                      >
                        Password does not match new password above.
                      </div>
                    )}
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    className="modal-btn btn-outline-primary"
                    onClick={this.handlePopUp}
                    data-testid="cancelbtn"
                  >
                    Cancel
                  </Button>
                  <Button
                    className="modal-btn"
                    color="primary"
                    type="submit"
                    role="submit"
                  >
                    Change Password
                  </Button>
                </ModalFooter>
              </form>
            </Modal>

            <li>
              <span
                onClick={this.navigateToPreferenceCenter}
                className="d-inline-flex align-items-center"
              >
                <i className="material-icons-outlined">settings</i>
                Preferences Center
              </span>
            </li>

            <li>
              <span
                onClick={this.userLogout}
                className="d-inline-flex align-items-center"
                id="logoutbtn"
              >
                <i className="material-icons-outlined icon-fs-20 font-weight-bold">
                  logout
                </i>
                Logout
              </span>
            </li>
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    setDisplayName: (name) => {
      dispatch(displayName(name));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
